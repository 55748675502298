interface IErrorPayload {
	message?: string;
	messageCode?: string;
	detail?: string;
}

export class NetworkError {
	public code: number;
	public description: string;
	public errorPayload: IErrorPayload;

	constructor(code: number, description: string, errorPayload: IErrorPayload) {
		this.code = code;
		this.description = description;
		this.errorPayload = errorPayload;
	}
}
