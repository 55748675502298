import { Component } from '@angular/core';

@Component({
	selector: 'app-default-notification-settings',
	standalone: true,
	imports: [],
	templateUrl: './default-notification-settings.component.html',
	styleUrl: './default-notification-settings.component.scss'
})
export class DefaultNotificationSettingsComponent {}
