import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
	HttpResponse
} from '@angular/common/http';
import { map, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { KorviuNotificationService } from '../../shared/services/korviu-notification.service';
import { NetworkError } from '../../shared/models/network-error';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private _korviuNotificationService: KorviuNotificationService) {}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			map((response: HttpEvent<any>) => {
				const successCodes: number[] = [200, 201, 204];
				if (response instanceof HttpResponse && !successCodes.includes(response.status)) {
					throw new HttpErrorResponse(response);
				}
				return response;
			}),
			catchError((err: HttpErrorResponse) => {
				const errorCode: number = err?.status;
				const errorDescription: string = err?.statusText;
				const networkError: NetworkError = new NetworkError(errorCode, errorDescription, err?.error);

				const backendErrorCodes: number[] = [0, 500, 503];
				if (backendErrorCodes.includes(networkError.code)) {
					this._korviuNotificationService.showError(networkError.description);
				}

				return throwError(networkError);
			})
		);
	}
}
