import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ReplaySubject, takeUntil } from 'rxjs';
import { SignUpService } from '../auth/core/sign-up.service';
import { UserTypes } from '../shared/types/user.model';

@Component({
	selector: 'app-accept-invitation',
	templateUrl: './accept-invitation.component.html',
	styleUrl: './accept-invitation.component.scss'
})
export class AcceptInvitationComponent implements OnInit, OnDestroy {
	private readonly _onDestroy$: ReplaySubject<void>;

	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private _signUpService: SignUpService
	) {
		this._onDestroy$ = new ReplaySubject<void>(1);
	}

	public ngOnInit(): void {
		this._route.paramMap.pipe(takeUntil(this._onDestroy$)).subscribe((params: ParamMap) => {
			const userTypeCodeParam = params.get('user_type');
			const invitationIdParam = params.get('invitation_id');
			const userType = this.registrationTypesByUserTypes.get(userTypeCodeParam) || 'us';
			this._router.navigate(['auth', 'sign-up', userType, params.get('code')]);
			this._signUpService.invitationId = +invitationIdParam;
			this._signUpService.userTypeCode = userTypeCodeParam as UserTypes;
		});
	}

	public ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	private readonly registrationTypesByUserTypes = new Map<string, 'us' | 'mexican'>([
		[UserTypes.MX, 'mexican'],
		[UserTypes.PA, 'us'],
		[UserTypes.PR, 'us'],
		[UserTypes.CO, 'us'],
		[UserTypes.RE, 'us'],
		[UserTypes.MU, 'us'],
		[UserTypes.SD, 'us']
	]);
}
