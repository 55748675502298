<div class="container flex flex-col items-center">
	<div class="pb-32">
		<go-back></go-back>
		<lang-translate-bar></lang-translate-bar>
	</div>
	<div class="image-container mb-12 md:mb-16 lg:mb-16">
		<img alt="" src="assets/img/not-found.png" class="image" />
		<img alt="" src="assets/img/not-found-mobile.png" class="image-mobile" />
	</div>
	<div class="not-found-title mb-6 md:mb-8 lg:mb-8">
		<h1>{{ 'notFound.oopsLooksLikeYou' | translate }}</h1>
	</div>
	<div class="not-found-label mb-12 md:mb-16 lg:mb-16">
		<span>{{ 'notFound.letsHelpYouFind' | translate }}</span>
	</div>
	<div class="not-found-button">
		<korviu-button color="primary" class="w-full" (click)="onBackToHomepageClick()">
			{{ 'notFound.backToHomepage' | translate }}
		</korviu-button>
	</div>
</div>
<div class="w-full mt-20 md:mt-6">
	<unigana-footer></unigana-footer>
</div>
