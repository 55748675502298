import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	handleError(error: any): void {
		if (error?.message?.includes('Failed to fetch dynamically imported module')) {
			window.location.reload();
		} else {
			console.error(error);
		}
	}
}
