import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FooterComponent } from '../shared/components/footer/footer.component';
import { GoBackComponent } from '../shared/components/go-back/go-back.component';
import { LangTranslateBarComponent } from '../shared/components/lang-translate-bar/lang-translate-bar.component';
import { KorviuButtonComponent } from '../shared/components/korviu-button/korviu-button.component';

@Component({
	selector: 'app-not-found-page',
	standalone: true,
	imports: [TranslateModule, FooterComponent, GoBackComponent, LangTranslateBarComponent, KorviuButtonComponent],
	templateUrl: './not-found-page.component.html',
	styleUrl: './not-found-page.component.scss'
})
export class NotFoundPageComponent {
	constructor(private _router: Router) {}

	public onBackToHomepageClick(): void {
		this._router.navigate(['']);
	}
}
