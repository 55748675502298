import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../shared/services/auth.service';
import { NetworkError } from '../../shared/models/network-error';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private _authService: AuthService) {}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const userToken: string = this._authService.userToken;
		const isApiUrl: boolean = request.url.startsWith(environment.apiUrl);

		if (userToken && isApiUrl) {
			request = request.clone({
				setHeaders: { Authorization: `Bearer ${userToken}` }
			});
		}

		return next.handle(request).pipe(
			catchError((error: NetworkError) => {
				if (error.code === 401) {
					this._authService.logOut();
				}

				return throwError(error);
			})
		);
	}
}
