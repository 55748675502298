import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { SafePipe } from '../shared/pipes/safe.pipe';

@Component({
	selector: 'app-terms-and-conditions',
	standalone: true,
	imports: [NgIf, SafePipe],
	templateUrl: './terms-and-conditions.component.html',
	styleUrl: './terms-and-conditions.component.scss'
})
export class TermsAndConditionsComponent {
	public pdfUrl: string;

	constructor(translateService: TranslateService) {
		this.pdfUrl = `assets/pdf/${translateService.currentLang}_UniGanaTCs.pdf`;
	}
}
