import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LocalStorageKeys } from './shared/types/local-storage-keys.enum';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
	public title: string = 'unigana';

	constructor(
		private _translateService: TranslateService,
		private _renderer: Renderer2,
		@Inject(DOCUMENT) private _document: Document
	) {}

	public ngOnInit(): void {
		this._translateService.setDefaultLang('es');
		this._setOnLangChangeListener();
		this._setUserLanguage();
	}

	private _setUserLanguage(): void {
		const languageFromLocalStorage: string = localStorage.getItem(LocalStorageKeys.PREFERRED_LANGUAGE);
		const availableLanguages: string[] = ['en', 'es'];
		let language: string;
		if (languageFromLocalStorage && availableLanguages.includes(languageFromLocalStorage)) {
			language = languageFromLocalStorage;
		} else {
			language = 'es';
		}
		this._translateService.use(language);
		this._savePreferredLanguage(language);
		this._setHTMLLang(language);
	}

	private _setOnLangChangeListener(): void {
		this._translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
			this._savePreferredLanguage(langChangeEvent?.lang);
			this._setHTMLLang(langChangeEvent?.lang);
		});
	}

	private _savePreferredLanguage(language: string): void {
		localStorage.setItem(LocalStorageKeys.PREFERRED_LANGUAGE, language);
	}

	private _setHTMLLang(lang: string) {
		this._renderer.setAttribute(this._document.documentElement, 'lang', lang);
	}
}
